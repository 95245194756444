<template>
	<basePopup :title="title" cancelText="닫기" okText="">
		<h6 class="inline-block">판매 결과</h6>
		<b-button
			class="p-0 ml-2 -top-0.5"
			v-b-tooltip.hover.html
			size="sm"
			title="* 배송상태 기준: 결제완료, 주문확인, 배송중, 배송대기, 배송완료, 교환요청중, 교환완료, 구매확정,배송지연<br>* 판매금액 산식: 주문수량*가격 (배송비 제외)"
			variant="no"
		>
			<b-icon icon="info-circle" />
		</b-button>

		<div class="flex">
			<b-form-checkbox class="mr-4 mt-1.5" v-model="opt.isIncludeAutoEncore">자동 앵콜 포함 </b-form-checkbox>
			<div class="flex top-1.5">
				<b-form-radio class="mr-6" v-model="opt.period" name="searchPeriod" value="">전체 </b-form-radio>
				<b-form-radio v-model="opt.period" name="searchPeriod" value="period" />
			</div>
			<datePicker class="w-1/7" model="opt.startDt" />
			<span class="p-2 lh-6">~</span>
			<datePicker class="w-1/7" model="opt.endDt" />

			<b-form-input
				class="w-1/5 ml-4"
				v-model.trim="opt.keyword"
				placeholder="딜번호, idx, 딜명 입력"
				@keyup.enter="search"
			/>
			<b-button class="ml-2 mr-4 px-10" @click="search" size="sm">검색 </b-button>
			<b-button @click="excelExport">엑셀 다운로드 요청</b-button>
		</div>

		<b-card class="my-4">
			<tb :inf="inf" :pr="ths" :res="res" limit="20" />
		</b-card>

		<vtb id="summaryTb" class="w-1/2 mx-auto" :data="summary" oneColumn>
			<template #slot="{ v, i }">
				<div :class="'td' + i" class="td">
					<p v-text="`${comma(v.cont1)}${i ? '개' : ''}`" />
					<p v-text="`${comma(v.cont2)}${i ? '원' : ''}`" />
				</div>
			</template>
		</vtb>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'

const now = new Date(),
	defOpt = {
		isIncludeAutoEncore: false,
		hottId: '',
		keyword: '',
		period: '',
		startDt: now,
		endDt: now,
	}

export default {
	components: { basePopup },
	data() {
		return {
			isShow: false,
			title: '',

			opt: { ...defOpt },
			lastOpt: {},
			res: {},

			summaryOpt: {},

			inf: [
				{ title: 'No.', model: 'idx', size: 3 },
				{ title: '딜 번호', key: 'dealNum', size: 4 },
				{ title: 'idx', key: 'productGroupIdx', size: 3 },
				{ title: '딜 명', key: 'dealName', size: 20, notCenter: 1 },
				{ title: '카테고리', key: 'category', size: 6, notCenter: 1 },
				{ title: '딜 구분', key: 'isEncore', size: 3 },
				{ title: '딜 유형', key: 'dealType', size: 3 },
				{ title: '딜 시작일', key: 'dealStartDate', size: 6, sortable: 1 },
				{ title: '딜 종료일', key: 'dealEndDate', size: 6, sortable: 1 },
				{ title: '판매량', key: 'sellCount', size: 3 },
				{ title: '판매금액', key: 'sellPrice', size: 6, tdClass: 'text-right pr-2' },
				{ title: '수수료', key: 'totalFee', size: 3 },
			],
			summary: [],
			//이건 서버에서 내려주는 데이터 양식이 별로다...
		}
	},
	methods: {
		open(item) {
			this.opt = { ...defOpt }
			this.summary = [{ title: '통계', cont1: '판매량', cont2: '판매금액', model: 'slot' }]
			if (item.name) this.title = `${item.name} / ${item.hottId} / ${item.currentInstaId}`
			else this.title = item.hottId
			this.title += ' 핫티스트 딜 판매 결과'
			this.opt.hottId = item.hottId

			this.summaryOpt = { hottId: item.hottId }
			this.search()
			this.isShow = true
		},
		search() {
			const opt = this.opt,
				data = {
					hottId: this.opt.hottId,
					isIncludeAutoEncore: opt.isIncludeAutoEncore,
					orderBy: getOrder('dealStartDate'),
					paging: getPaging(0, 20),
				},
				error = []
			if (opt.keyword) data.searchKey = getSearchKey(['dealNum', 'dealName', 'productGroupIdx'], opt.keyword)
			if (opt.period) {
				if (opt.startDt > opt.endDt) error.push('요청 기간을 확인해주세요')
				else {
					data.startDt = opt.startDt.get_time('d', 0, '')
					data.endDt = opt.endDt.get_time('d', 0, '')

					this.summaryOpt.startDt = data.startDt
					this.summaryOpt.endDt = data.endDt
				}
			} else {
				delete this.summaryOpt.startDt
				delete this.summaryOpt.endDt
			}
			this.summaryOpt.isIncludeAutoEncore = opt.isIncludeAutoEncore

			if (error.length) alert.w(error)
			else {
				this.lastOpt = data
				this.changePage(1)
			}

			postApi('/hottistSelect/dealSummary', this.summaryOpt).then(({ summary }) => {
				this.summary = [{ title: '통계', cont1: '판매량', cont2: '판매금액', model: 'slot', tdClass: 'th' }]
				;[
					['총합', 'total'],
					['딜당 평균', 'avg'],
					['최댓값', 'max'],
					['최솟값', 'min'],
					['중앙값', 'mid'],
				].map(v => {
					const sellCt = Math.round(summary[v[1] + 'Cnt'] * 10) / 10,
						sellPrice = Math.round(summary[v[1] + 'Price'] * 10) / 10
					this.summary.push({ title: v[0], cont1: sellCt, cont2: sellPrice, model: 'slot' })
				})
			})
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('/hottistSelect/dealList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list.map(v => {
						if (v.productGroupCategory1)
							v.category = v.productGroupCategory1
							//v.category = `${v.productGroupCategory1} - ${v.productGroupCategory2}`
						;['startDt', 'endDt'].map(k => {
							v[k] = v[k].substr(0, 10)
						})
						v.sellCount = comma(v.sellCount)
						v.sellPrice = comma(v.sellPrice) + '원'

						v.dealStartDate = v.startDt
						v.dealEndDate = v.endDt

						v.totalFee += '%'
						return v
					})
					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel(this.title)

			ex.date(opt.startCreatedDate, opt.endCreatedDate, '판매기간')
			if (opt.searchKey) ex.key('검색어', opt.searchKey[0].value)
			reload(this, false, ex)
		},
	},
}
</script>

<style lang="scss">
#summaryTb {
	.title,
	.td0 {
		font-weight: bold;
		text-align: center;

		p {
			text-align: center !important;
		}
	}

	.td {
		p {
			display: inline-block;
			width: 50%;
			padding: 0 0.5rem;
			text-align: right;

			&:first-child {
				border-right: 1px solid;
			}
		}
	}
}
</style>

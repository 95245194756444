<template>
	<basePopup :cancelFunc="cancel" :okFunc="save" okText="등급 업로드" title="일괄 등급 변경">
		<div class="flex">
			<b-form-file
				v-model="input.file"
				placeholder="파일을 선택해주세요."
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				browse-text="파일 찾기"
			/>
		</div>
		<h6 class="mt-4">
			양식에 맞게 정확하게 입력 후 파일을 업로드하세요.<br />
			300개 행 이하의 xlsx 파일만 업로드 가능합니다.<br />
			<br />
			[첨부파일 양식] <br />A열- 변경예정 핫티스트의 멤버 Seq <br />B열- 변경할 등급
		</h6>
		<clipboard :pr="ths" />
	</basePopup>
</template>

<script>
import { uploadFile } from 'libs/axios'
import basePopup from 'comp/local/basePopup'
import clipboard from 'comp/local/clipboard'

export default {
	components: { basePopup, clipboard },
	data() {
		return {
			isShow: false,
			title: '',

			input: {
				file: null,
			},
		}
	},
	methods: {
		open(item) {
			this.input.file = null
			this.isShow = true

			setTimeout(() => this.clipboard.clear())
		},
		save() {
			const input = this.input,
				e = []
			let preFunc,
				data = null

			if (!input.file) {
				if (this.clipboard.e) e.push(this.clipboard.e)
				//엑셀 클립보드로 붙여넣기
				else
					preFunc = postApi('api/backoffice/hottistHandle/updateAllSelectedHottistClass', {
						data: this.clipboard.getData,
					})
			}
			//일반 엑셀 파일 업로드
			else
				preFunc = uploadFile(
					this.input.file,
					'excel',
					'api/backoffice/hottistHandle/updateAllSelectedHottistClass',
					'post'
				)

			if (e.length) alert.w(e)
			else
				preFunc.then(() => {
					alert.s('일괄 등급 변경에 성공했습니다.')
					this.isShow = false
				})
		},
		cancel() {
			if (this.input.file)
				confirm('등록을 완료하지 않은 파일이 있습니다.\r\n등록을 취소하시겠습니까?', () => {
					this.isShow = false
				})
			else this.isShow = false
		},
	},
}
</script>

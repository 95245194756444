<!--핫티스트 조회 · 수정 페이지 메인-->
<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/5 pr-2">
					<h6>&nbsp;</h6>
					<vSelect v-model="opt.dateType" :clearable="false" :options="dateTypeOpts" />
				</div>
				<div class="w-1/3 pr-2">
					<h6>&nbsp;</h6>
					<div class="flex">
						<b-form-radio class="w-20 mt-1.5" v-model="opt.period" name="periodRadio" value="">
							<span class="mr-2">전체</span>
						</b-form-radio>
						<b-form-radio class="mt-1.5" v-model="opt.period" name="periodRadio" value="period" />
						<div class="flex">
							<datePicker placeholder="from" model="opt.startDate" />
							<span class="p-2 lh-6">~</span>
							<datePicker placeholder="to" model="opt.endDate" />
						</div>
					</div>
				</div>
				<div class="w-1/5 pr-2">
					<h6>구분</h6>
					<vSelect v-model="opt.userType" :clearable="false" :options="userTypeOpts" />
				</div>
				<div class="w-1/5 pr-2">
					<h6>신청상태</h6>
					<vSelect v-model="opt.hottistStatus" :clearable="false" :options="hottistStatusOpts" />
				</div>
				<div class="w-1/5 pr-2">
					<h6>핫티스트 구분</h6>
					<vSelect
						v-model="opt.hottistType"
						:clearable="false"
						:disabled="opt.userType && opt.userType.value == 'L'"
						:options="hottistTypeOpts"
					/>
				</div>
				<div class="w-1/5">
					<h6>등급</h6>
					<vSelect v-model="opt.hottistClass" :clearable="false" :options="hottistClassOpts" />
				</div>
			</div>
			<div class="flex">
				<div class="w-1/5 pr-2">
					<h6>판매계정</h6>
					<vSelect v-model="opt.isSalesAccount" :clearable="false" :options="isSalesAccountOpts" />
				</div>
				<div class="w-1/5 pr-2">
					<h6>콘텐츠 카테고리</h6>
					<vSelect v-model="opt.contentCategory" :clearable="false" :options="contentCategoryOpts" />
				</div>
				<div class="w-1/5 pr-2">
					<h6>콘텐츠 퀄리티</h6>
					<vSelect v-model="opt.contentQuality" :clearable="false" :options="contentQualityOpts" />
				</div>
				<div class="w-3/5">
					<h6>검색어</h6>
					<div class="flex">
						<vSelect class="w-80" v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
						<b-form-input
							class="mx-4"
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="w-40 px-6" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<b-card>
			<div class="absolute top-0 right-0 flex">
				<b-button
					class="mr-4"
					@click="
						() => {
							this.hottistChangeClassPopup.open()
						}
					"
					>일괄 등급변경</b-button
				>
				<b-button @click="excelExport">엑셀 다운로드 요청</b-button>
			</div>
			<tb class="mt-12" :inf="inf" :res="res" />
		</b-card>
		<hottistPopup
			:contentCategoryOpts="contentCategoryOpts"
			:contentQualityOpts="contentQualityOpts"
			:hottistTypeOpts="hottistTypeOpts"
			:pageKey="pageKey"
			:pr="ths"
			:userTypeOpts="userTypeOpts"
			cName="hottistPopup"
		/>
		<dealCalendarPopup :pr="ths" cName="dealCalendarPopup" />
		<sellResultPopup :pr="ths" cName="sellResultPopup" />
		<hottistChangeClassPopup :pr="ths" cName="hottistChangeClassPopup" />
	</div>
</template>

<script>
import hottistPopup from 'pages/hottistPopup'
import dealCalendarPopup from 'pages/dealCalendarPopup'
import sellResultPopup from 'pages/sellResultPopup'
import hottistChangeClassPopup from 'pages/hottistChangeClassPopup'

const now = new Date(),
	dateTypeOpts = [
		{ label: '가입일', value: 'joinDt' },
		{ label: '승인일', value: 'acceptDt' },
	],
	isSalesAccountOpts = [
		{ label: '전체', value: '' },
		{ label: 'X', value: false },
		{ label: 'O', value: true },
	],
	searchKeyOpts = [
		{ label: '핫트아이디', value: 'hottId' },
		{ label: '이름', value: 'memberName' },
		{ label: '휴대폰 번호', value: 'phone' },
		{ label: '인스타아이디', value: 'snsLink' },
		{ label: '담당자', value: 'name' },
		{ label: '해시태그', value: 'hashTag' },
		{ label: '메모', value: 'comment' },
	],
	defOpt = {
		dateType: dateTypeOpts[0],
		period: '',
		startDate: getMonth(now, -1),
		endDate: now,

		hottistType: '',
		hottistClass: '',
		contentCategory: '',
		isSalesAccount: isSalesAccountOpts[0],
		contentQuality: '',

		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}

export default {
	components: { hottistPopup, dealCalendarPopup, sellResultPopup, hottistChangeClassPopup },
	props: { hottId: { default: '' } },
	data() {
		return {
			dateTypeOpts,
			userTypeOpts: [],
			hottistTypeOpts: [],
			hottistStatusOpts: [],
			hottistClassOpts: [],
			contentCategoryOpts: [],
			isSalesAccountOpts,
			contentQualityOpts: [],

			searchKeyOpts,
			opt: { ...defOpt },
			defOpt,

			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '핫티스트 구분', key: 'hottistTypeValue', size: 3 },
				{
					title: '핫트아이디',
					key: 'hottId',
					size: 3,
					sortable: 1,
				},
				{
					title: '이름',
					model: 'button',
					size: 2,
					text: item => item.memberName,
					bVariant: 'no',
					class: 'text-primary',
					func: item => this.hottistPopup.open(item, true),
				},
				{ title: '연락처', key: 'phone', size: 2 },

				{ title: '인스타아이디', key: 'currentInstaId', model: 'insta', size: 3 },

				{ title: '가입일', key: 'registerTime', size: 3, sortable: 1 },
				{ title: '승인일', key: 'hottDealAcceptDt', size: 3, sortable: 1 },
				{ title: '등급', key: 'class', size: 1 },
				{ title: '콘텐츠 1', key: 'contentCategoryValue1', size: 2 },
				{ title: '콘텐츠 2', key: 'contentCategoryValue2', size: 2 },
				{ title: '콘텐츠 3', key: 'contentCategoryValue3', size: 2 },
				{ title: '콘텐츠 퀄리티', key: 'contentQualityValue', size: 1 },
				{
					title: '달력',
					model: 'button',
					icon: 'calendar4-week',
					size: 2,
					func: this.openCalendar,
				},
				{
					title: '판매결과',
					model: 'button',
					icon: 'graph-up',
					size: 2,
					func: this.openSellResult,
				},
				{ title: '메모', model: 'memoBtn', domain: 'MEMBER', size: 2 },
				{ title: '변경내역', model: 'memoBtn', domain: 'HOTTIST_HISTORY', readOnly: true, size: 2 },
				{ title: '담당자', key: 'adminName', size: 2 },
			],
			lastOpt: {},
			res: {},
			pageKey: 'hottistList',
		}
	},
	methods: {
		search() {
			const opt = this.opt,
				dateType = getDropdownValue(opt.dateType),
				userType = getDropdownValue(opt.userType),
				hottistType = getDropdownValue(opt.hottistType),
				hottistStatus = getDropdownValue(opt.hottistStatus),
				hottistClass = getDropdownValue(opt.hottistClass),
				category = getDropdownValue(opt.contentCategory),
				isSales = getDropdownValue(opt.isSalesAccount),
				contentQuality = getDropdownValue(opt.contentQuality),
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword,
				error = [],
				data = {
					orderBy: getOrder('hottDealAcceptDt'),
					paging: getPaging(),
				}

			if (opt.period) {
				data.startDate = opt.startDate.get_time('d', 0, '')
				data.endDate = opt.endDate.get_time('d', 0, '')
				data.dateType = dateType
			}

			if (userType) data.userType = userType
			if (hottistStatus) data.saveStatus = hottistStatus
			if (hottistType && userType != 'L') data.hottistType = hottistType
			if (hottistClass) data.hottistClass = hottistClass
			if (category) data.category = category
			if (isSales !== '') data.isSales = isSales
			if (contentQuality) data.contentQuality = contentQuality

			//if (searchVal) data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)
			if (searchVal) {
				data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)
				if (data.searchKey.length > 1) data.searchKey.splice(data.searchKey.length - 1, 1)
				//메모가 들어가면 검색이 이상해진다?
			}

			if (error.length) alert.w(error)
			else {
				this.lastOpt = data
				this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1

			postApi('api/backoffice/hottistSelect/hottistList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					const changeNormalMember = this.lastOpt.userType && this.lastOpt.userType == 'L'
					res.list = res.list.map(v => {
						v.memberSeq = v.seq
						v.memberName = v.name
						return v
					})

					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()

			ex.date(opt.startDate, opt.endDate, '승인일')
			ex.dropdown(opt.userType, this.userTypeOpts, '구분', '전체')
			ex.dropdown(opt.hottistType, this.hottistTypeOpts, '핫티스트 구분', '전체')
			ex.dropdown(opt.hottistStatus, this.hottistStatusOpts, '신청상태', '전체')
			ex.dropdown(opt.hottistClass, this.hottistClassOpts, '등급', '전체')
			ex.dropdown(opt.category, this.contentCategoryOpts, '콘텐츠 카테고리', '전체')
			if (typeof opt.isSales != 'undefined') ex.key('판매계정', opt.isSales ? 'O' : 'X')
			else ex.key('판매계정', '전체')
			ex.dropdown(opt.contentQuality, this.contentQualityOpts, '콘텐츠 퀄리티', '전체')
			ex.search(opt.searchKey, searchKeyOpts)

			ex.go(this)
		},
		openCalendar(item) {
			this.dealCalendarPopup.open(item)
		},
		openSellResult(item) {
			this.sellResultPopup.open(item)
		},
	},
	created() {
		getConfig(
			'user_type, influencer_class, influencer_status, hottist_type, content_quality, content_category',
			false
		).then(res => {
			this.userTypeOpts = res.user_type
			defOpt.userType = res.user_type[0]
			this.hottistClassOpts = res.influencer_class
			defOpt.hottistClass = res.influencer_class[0]
			this.hottistStatusOpts = res.influencer_status
			defOpt.hottistStatus = res.influencer_status[0]
			this.hottistTypeOpts = res.hottist_type
			defOpt.hottistType = res.hottist_type[0]
			this.contentQualityOpts = res.content_quality
			defOpt.contentQuality = res.content_quality[0]
			this.contentCategoryOpts = res.content_category
			defOpt.contentCategory = res.content_category[0]
			this.opt = { ...defOpt }
		})
		this.search()
		setTimeout(() => {
			if (this.hottId) this.openSellResult({ hottId: this.hottId })
		}, 100)
	},
}
</script>
